// This function reformats pricing table matrix data to fullfill the
// mobile design requirements specified in the following Figma URL ...
// https://www.figma.com/file/xL5G2nUcIpOUyIyI7vlTE5/Pricing?node-id=60%3A1084&t=SWUi1Q5i1ocshD6o-0
const formatAccordionTableData = (table) => {
  let accordionTableData = [...table]

  // Create an array of accordion sections by parsing the first table row.
  // Also remove the first table row
  const accordionSections = accordionTableData
    .shift(1)
    .row.slice(1)
    .map((row) => {
      const input = row?.link?.[0]?.textToHyperlink || row.input
      return { heading: input, table: [] }
    })

  // Create an empty but iterable array the length of the accordion sections
  const accordionSectionsArray = Array(accordionSections.length).fill()

  // Remove another table row containing a horizontal rule
  accordionTableData.shift()

  // Move the last three rows from the table and add them to the beginning of the table.
  // These rows contain the CTA buttons and a horizontal rule that need to be at the top of the table.
  const ctaRows = accordionTableData.splice(accordionTableData.length - 3, 3)
  accordionTableData.unshift(ctaRows[1], ctaRows[2], ctaRows[0])

  // Parse the remaining table blok components and add data
  // to their corresponding accordion section table
  accordionTableData.forEach((tableBlok) =>
    accordionSectionsArray.forEach(
      (_accordionSection, accordionSectionIndex) => {
        if (tableBlok.component === 'row') {
          return accordionSections[accordionSectionIndex].table.push({
            ...tableBlok,
            row:
              tableBlok.row.length > 1
                ? [tableBlok.row[0], tableBlok.row[accordionSectionIndex + 1]]
                : [tableBlok.row[0]],
          })
        }

        return accordionSections[accordionSectionIndex].table.push({
          ...tableBlok,
        })
      }
    )
  )

  return accordionSections
}

export default formatAccordionTableData
