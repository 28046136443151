import formatAccordionTableData from '@helpers/format-accordion-table-data'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  useMediaQuery,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import renderBloks from '@renderBloks'
import Accordion from '@system/accordion'
import ContentContainer from '@system/content-container'
import PageContainer from '@system/page-container'
import ACC_THEME from '@themes'
import React from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles({
  tableMatrixRoot: {
    padding: '24px',
  },
  tableMatrixAccordionRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  tableContainer: {
    backgroundColor: '#fff',
    '& td': { border: ({ variant }) => (variant === 'pricing' ? 0 : null) },
    '& td:nth-child(n+2)': { width: '20%' },
  },
  tableContainerAccordion: {
    backgroundColor: 'unset',
    '& tr': {
      display: 'flex',
      justifyContent: 'space-between',
      '&:first-child': {
        borderBottom: 'none',
        borderTop: 'none',
      },
    },
    '& th': {
      '&:empty': {
        display: 'none',
      },
    },
    '& td': {
      padding: '10px 16px',
      '&:empty': {
        display: 'none',
      },
      '&:nth-child(n+2)': { flex: 'none', width: 'unset' },
      '& img': {
        display: 'block',
      },
    },
  },
})

const TableMatrix = ({ blok }) => {
  const { table, topHeader, variant } = blok
  const classes = useStyles({ variant })
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))

  const renderContent = () => {
    if (handHeldDevice && variant === 'pricing') {
      const accordionSections = formatAccordionTableData(table)

      return (
        <div className={classes.tableMatrixAccordionRoot}>
          {accordionSections.map((accordionSection, index) => (
            <Accordion
              key={`table_matrix_accordion_${index}`}
              title={accordionSection.heading}
              variant="table"
            >
              <TableContainer
                className={[
                  classes.tableContainer,
                  classes.tableContainerAccordion,
                ].join(' ')}
                elevation={3}
              >
                <Table aria-label="Table">
                  <TableBody>
                    {renderBloks(accordionSection.table, { variant })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Accordion>
          ))}
        </div>
      )
    }

    return (
      <div className={classes.tableMatrixRoot}>
        <TableContainer
          backgroundColor="textPrimary"
          className={classes.tableContainer}
          component={variant !== 'pricing' ? Paper : 'div'}
          elevation={3}
        >
          <Table aria-label="Table">
            <TableHead>{renderBloks(topHeader)}</TableHead>
            <TableBody>{renderBloks(table, { variant })}</TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

  return (
    <SbEditable content={blok}>
      <PageContainer>
        <ContentContainer>{renderContent()}</ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default TableMatrix
